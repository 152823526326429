<form [formGroup]="formGroup" *ngIf="formGroup" class="create-group-container">
  <h2>{{headerName}}</h2>
  <div class="row-container">
  <h3>Group Name*</h3>
  <input
    class = "need-css-validation"
    type="text"
    placeholder="Enter Group Name"
    required
    formControlName="groupName"
  />
  </div>
  <ejs-tab id="tab_default">
    <e-tabitems>
      <e-tabitem [header]="headerText[0]" class="e-tab-wrap">
        <ng-template #content> 
            <div class="filter-container">
              <div class="filter-text-container"><p class="filter-text">Filter By</p></div>
               <div><app-filter-component (filterEvent)="onFilterValuechange($event)" (selectFilterEvent)="onfilterSelect($event)" [filterMenuListItems]="filterMenuListItems" [tab]='true'></app-filter-component></div>
            </div>
            <app-tree-grid *ngIf="getGatewayListServiceUrl" (treeGridEvent)="gridDataEvent($event)" idMapping="id" [columns]="groupDetailsTableDefinition"  [autoCheckHierarchy]="true" [allowSorting]="true" [serviceUrl]="getGatewayListServiceUrl" [allowPaging]="true" [gridUIType]='GridUIType' [allowMultiselect]="true" ></app-tree-grid>
        </ng-template>
      </e-tabitem>
   </e-tabitems >
</ejs-tab>
<div class="modal-footer">
    <app-custom-button [buttonData]="cancelButtonData" (buttonClick)="onCancel()"/>
    <app-custom-button [buttonData]="createButtonData" (buttonClick)="buttonClick()"/>
  </div>
</form>