export class AdminInfoRoutes {
  public static readonly BASE_ADMIN_ROUTE = 'admin';
  public static readonly ADMIN_MODULE_ROUTE = 'admin-dashboard';
  public static readonly ADMIN_DASHBOARD_INFO = 'admin-dashboard-info';
}

export class AreasInfoRoutes {
  public static readonly AREAS_BASE_ROUTE = 'areas-info';
  public static readonly AREA_DETAILS_ROUTE = 'area-detail';
}
export class ArtifactManagementRoutes {
  public static readonly ARTIFACT_MANAGEMENT_BASE_ROUTE = 'artifact-management';
  public static readonly UPLOAD_ARTIFACT_OS = 'upload-artifact-os';
  public static readonly UPLOAD_ARTIFACT_FIRMWARE = 'upload-artifact-firmware';
  public static readonly UPLOAD_ARTIFACT_SM = 'upload-artifact-sm';
}

export class CampaignManagementRoutes {
  public static readonly CAMPAIGN_MANAGEMENT_BASE_ROUTE = 'campaign-management';
  public static readonly CAMPAIGN_MANAGEMENT_GROUP_SUMMARY = 'group-summary';
  public static readonly CAMPAIGN_DETAILS = 'campaign-detail';
  public static readonly CREATE_GROUP = 'create-group';
  public static readonly UPDATE_GROUP = 'update-group';
  public static readonly CAMPAIGN_MANAGEMENT_GROUP_DETAILS = 'campaign-management-group-details';
  public static readonly CAMPAIGNS_IN_GROUP = 'campaigns-in-group';
  public static readonly CAMPAIGN_MANAGEMENT_OPERATIONS = 'operations';
}

export class CustomerInfoRoutes {
  public static readonly BASE_CUSTOMER_ROUTE = 'customer';
  public static readonly CUSTOMER_MODULE_ROUTE = 'dashboard-info';
  public static readonly CUSTOMER_DASHBOARD_INFO = 'dashboard';
}

export class EnterpriseInfoRoutes {
  public static readonly ENTERPRISE_BASE_ROUTE = 'enterprises-info';
  public static readonly ENTERPRISE_DETAILS = 'enterprise-detail';
  public static readonly ENTERPRISE_LIST = 'enterprises';
}

export class EquipmentsInfoRoutes {
  public static readonly EQUIPMENTS_BASE_ROUTE = 'equipments-info';
  public static readonly EQUIPMENT_DETAILS_ROUTE = 'equipment-detail';
}

export class GatewayInfoRoutes {
  public static readonly GATEWAY_INFO_BASE_ROUTE = 'gateways-info';
  public static readonly ROUTE_TO_GATEWAY_LIST = 'gateways';
  public static readonly ROUTE_TO_GATEWAY_DETAILS = 'gateway-details';
}

export class NotificationManagementRoutes {
  public static readonly NOTIFICATION_BASE_ROUTE = 'notification-management';
  public static readonly NOTIFICATION_DETAILS_ROUTE = 'notification-detail';
  public static readonly NOTIFICATION_LIST_ROUTE = 'notification-list';
}
export class SensorInfoRoutes {
  public static readonly SENSOR_INFO_BASE_ROUTE = 'sensors-info';
  public static readonly ROUTE_TO_SENSOR_LIST = 'sensors';
  public static readonly ROUTE_TO_SENSOR_DETAILS = 'sensor-detail';
  public static readonly ROUTE_TO_SENSOR_TELEMETRY_GRAPH_S7100 = 'sensor-telemetry-graph-s7100';
}

export class SiteInfoRoutes {
  public static readonly SITE_BASE_ROUTE = 'sites-info';
  public static readonly SITE_DETAILS_ROUTE = 'site-detail';
}

export class SystemInfoRoutes {
  public static readonly SYSTEM_INFO_BASE_ROUTE = 'system-info';
  public static readonly ROUTE_TO_GATEWAY = 'gateway';
  public static readonly ROUTE_TO_SENSOR = 'sensor';
  public static readonly ROUTE_TO_SENSOR_TELEMETRY_GRAPH_S7100 = 'graph';
}

export class TenantInfoRoutes {
  public static readonly TENANT_INFO_BASE_ROUTE = 'tenant-info';
  public static readonly ROUTE_TO_TENANT_LIST = 'tenant';
  public static readonly ROUTE_TO_TENANT_DETAILS = 'tenant-detail';
}

export class UnitsInfoRoutes {
  public static readonly UNITS_BASE_ROUTE = 'units-info';
  public static readonly UNIT_DETAILS_ROUTE = 'unit-detail';
}

export class UserManagementRoutes {
  public static readonly USERS_BASE_ROUTE = 'user-management';
  public static readonly USER_LIST_ROUTE = 'user-list';
  public static readonly USER_DETAILS_ROUTE = 'user-details';
  public static readonly ROLE_CREATE_ROUTE = 'role-create';
}

export class EventsInfoRoutes {
  public static readonly EVENTS_INFO_BASE_ROUTE = 'events-info';
  public static readonly HISTORICAL_EVENTS = 'historical-events';
  public static readonly GLOBAL_EVENTS = 'global-events';
  public static readonly EVENT_PARAMETERS = 'event-parameters';
}
