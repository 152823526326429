import { ApiResponse } from '../../models/api-response';
import { catchError, map, Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { USER_API_ENDPOINTS, USER_API_ENDPOINTS_DASHBOARD } from '../../app-constants/api-constants';
import { UserDetails } from '../../../admin/user-management/models/user-details';
import { Scope } from '../../app-constants/asset-constants';

@Injectable({
  providedIn: 'root'
})
export class UserCommonService {
  constructor(
    private http:HttpClient,
    private errorHandlerService:ErrorHandlerService
  ) { }

  public getUsersBySearchString(tenantId: string, searchText: string): Observable<UserDetails[]> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.GET_USERS_BY_SEARCH_QUERY(tenantId, searchText, 1000, 0);
    return this.http.get<ApiResponse<UserDetails[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data))
      .pipe(catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      }));
  }

  public getUsersByScope(tenantId: string, scopeIdOfHierarchy: string): Observable<UserDetails[]> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.GET_USERS_BY_SITE_SCOPE(tenantId, scopeIdOfHierarchy);
    return this.http.get<ApiResponse<UserDetails[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getUserList(tenantId: string, sortBy: string, pageNumber: string, pageSize: string): Observable<UserDetails[]>  {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.GET_USER_LIST(tenantId, sortBy, pageNumber, pageSize);
    return this.http.get<ApiResponse<UserDetails[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getUsersByScopeIdOfHierarchy(tenantId: string, scopeIdOfHierarchy: string): Observable<UserDetails[]> {
    const scopeToPass = `scope=${scopeIdOfHierarchy}`;
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.GET_USER_LIST_BY_SCOPE(tenantId) + `?$filter=${scopeToPass}`;
    return this.http.get<ApiResponse<UserDetails[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getUsersBySearchText(tenantId: string, scopeIdOfHierarchy: string, searchText: string): Observable<UserDetails[]> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.GET_USERS_FOR_SCOPE;
    const searchRequest = { tenantId: tenantId, searchString: searchText, scope: scopeIdOfHierarchy };
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<ApiResponse<UserDetails[]>>(url, JSON.stringify(searchRequest), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getUsersByFilteredScope(tenantId: string, scopeIdOfHierarchy: string, listOfOktaIds: string[]): Observable<UserDetails[]> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.GET_USERS_FOR_SCOPE;
    const usersByScopeRequest = { tenantId: tenantId, oktaIds: listOfOktaIds, scope: scopeIdOfHierarchy };
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<ApiResponse<UserDetails[]>>(url, JSON.stringify(usersByScopeRequest), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getAbsoluteCountOfUsers(tenantId: string): Observable<number> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.GET_USERS_ABSOLUTE_COUNT(tenantId);
    const headers = new HttpHeaders({
      'scope': Scope.DEFAULT_SCOPE,
    });
    return this.http.get<number>(url, { headers }).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public updateUser(tenantId: string, saveEnterpriseRequest: UserDetails, scope: string): Observable<UserDetails> {
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'scope': scope }) };
    const body = saveEnterpriseRequest;
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.UPDATE_USER_DETAILS(tenantId);
    return this.http.put<ApiResponse<UserDetails>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }
}
