import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-artifact-status-tooltip',
  templateUrl: './artifact-status-tooltip.component.html',
  styleUrls: ['./artifact-status-tooltip.component.scss']
})
export class ArtifactStatusTooltipComponent {
  @Input() reason: string = '';
  @Input() otaSyncStatus: string = '';
}
