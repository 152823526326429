import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap } from 'rxjs';
import { PermissionGroup } from 'src/app/customer/user-management/models/permission-group';
import { UserList } from 'src/app/customer/user-management/models/user-list';
import { NOTIFICATION_API_ENDPOINTS, TENANTS_API_ENDPOINTS_DASHBOARD, USER_API_ENDPOINTS, USER_API_ENDPOINTS_DASHBOARD } from 'src/app/shared/app-constants/api-constants';
import { Tenant } from 'src/app/shared/models/admin/tenant';
import { ApiResponse } from 'src/app/shared/models/api-response';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler/error-handler.service';
import { environment } from 'src/environments/environment';
import { NotificationList } from '../../notification-rules-management/model/notification-list';
import { CreateRole } from '../models/create-role';
import { UserDetails, UserRole, UserRoleAssignment } from '../models/user-details';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private errorHandlerService: ErrorHandlerService,
    private http: HttpClient
  ) { }

  public getUserListServiceUrl(tenantId: string): string {
    return environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.GET_USER_LIST(tenantId);
  }

  public getUserList(tenantId: string, sortBy: string, pageNumner: string, pageSize: string): Observable<ApiResponse<UserList[]>>  {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.GET_USER_LIST(tenantId, sortBy, pageNumner, pageSize);
    return this.http.get<ApiResponse<UserList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public createUser(tenantId: string, user: UserDetails): Observable<UserDetails> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.CREATE_USER(tenantId);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = user;
    return this.http.post<ApiResponse<UserDetails>>(url, JSON.stringify(body), headers).pipe(
      tap(response => this.errorHandlerService.handleCustomError(response.error)),
      map(response => response.data),
      catchError(error => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public createGlobalUser(user: UserDetails): Observable<UserDetails> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.CREATE_GLOBAL_USER;
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = user;
    return this.http.post<ApiResponse<UserDetails>>(url, JSON.stringify(body), headers).pipe(
      tap(response => this.errorHandlerService.handleCustomError(response.error)),
      map(response => response.data),
      catchError(error => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getRoles(): Observable<UserRole[]> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.USER_ROLES;
    return this.http.get<ApiResponse<UserRole[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getUserDetails(tenantId: string, oktaId: string): Observable<UserDetails> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.GET_USER_DETAILS(tenantId, oktaId);
    return this.http.get<ApiResponse<UserDetails>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getGlobalUserDetails(oktaId: string): Observable<UserDetails> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.GET_GLOBAL_USER(oktaId);
    return this.http.get<ApiResponse<UserDetails>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public deleteRoleAssignment(tenantId: string, userRoleAssignment:UserRoleAssignment): Observable<any> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.DELETE_ROLE_ASSIGNMENT(tenantId);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = JSON.stringify(userRoleAssignment);
    const options = {
      headers: headers,
      body: body,
    };
    return this.http.delete<ApiResponse<object>>(url, options)
      .pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
        catchError((error) => {
          this.errorHandlerService.showErrorPopUp(error);
          throw error;
        })
      );
  }

  public createRoleAssignment(tenantId: string, userRoleAssignment:UserRoleAssignment): Observable<UserRoleAssignment[]> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.CREATE_ROLE_ASSIGNMENT(tenantId);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = userRoleAssignment;
    return this.http.post<ApiResponse<UserRoleAssignment[]>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public updateUserDetails(tenantId: string, saveEnterpriseRequest: UserDetails): Observable<UserDetails> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.UPDATE_USER_DETAILS(tenantId);
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = saveEnterpriseRequest;
    return this.http.put<ApiResponse<UserDetails>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getTenants(): Observable<Tenant[]> {
    const url = environment.dashboardApiBaseUrl + TENANTS_API_ENDPOINTS_DASHBOARD.GET_TENANTS;
    return this.http.get<Tenant[]>(url).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getUsersByScope(scope: string, tenantId: string): Observable<UserList[]> {
    const params = { tenantId: tenantId, scope: scope };
    const url = environment.dashboardApiBaseUrl + this.makeRequestUrl(USER_API_ENDPOINTS.GET_USERS_BY_SCOPE, params);
    return this.http.get<ApiResponse<UserList[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getPermissions(): Observable<PermissionGroup[]> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.GET_PERMISSIONS;
    return this.http.get<ApiResponse<PermissionGroup[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public createRole(request:CreateRole): Observable<CreateRole> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.CREATE_ROLE;
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = request;
    return this.http.post<ApiResponse<CreateRole>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  makeRequestUrl(urlPattern: string, params: { [key: string]: string }): string {
    let url = urlPattern;
    for (const key in params) {
      if (key in params) {
        url = url.replace(`{${key}}`, params[key]);
      }
    }
    return url;
  }

  public getUserDetailsByOktaId(tenantId: string, oktaId: string, scope: string): Observable<UserDetails> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.GET_USER_DETAILS_BY_OKTAID(tenantId, oktaId);
    const scopeHeader = { 'scope': scope };
    return this.http.get<ApiResponse<UserDetails>>(url, { headers: new HttpHeaders(scopeHeader) }).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getNotificationsByUser(tenantId: string, request: string[]): Observable<NotificationList[]> {
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = request;
    const url = environment.dashboardApiBaseUrl + NOTIFICATION_API_ENDPOINTS.GET_NOTIFICATIONS_BY_USER(tenantId);
    return this.http.post<ApiResponse<NotificationList[]>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getRolesByRoleType(roleType: string): Observable<UserRole[]> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.GET_ROLES_BY_ROLETYPE(roleType);
    return this.http.get<ApiResponse<UserRole[]>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public getRoleByRoleId(roleId: string): Observable<UserRole> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS_DASHBOARD.GET_ROLE_BY_ROLEID(roleId);
    return this.http.get<ApiResponse<UserRole>>(url).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }

  public updateRole(request:CreateRole, roleId: string): Observable<CreateRole> {
    const url = environment.dashboardApiBaseUrl + USER_API_ENDPOINTS.ROLE;
    const headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const body = { id: roleId, name: request.name, description: request.description, roleType: request.roleType, permissions: request.permissions };
    return this.http.put<ApiResponse<CreateRole>>(url, JSON.stringify(body), headers).pipe(tap(response => { this.errorHandlerService.handleCustomError(response.error); }), map((response) => response.data)).pipe(
      // Using catchError to handle errors
      catchError((error) => {
        this.errorHandlerService.showErrorPopUp(error);
        throw error;
      })
    );
  }
}
