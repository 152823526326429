import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ImageEditorComponent } from '@syncfusion/ej2-angular-image-editor';
import { debounceTime, Subscription } from 'rxjs';
import { ButtonLabels, ButtonNames, ButtonTypes, CustomEventSource, CustomEventType, ModalTemplateTypes } from 'src/app/shared/app-constants/shared.enum';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { AssetPhotosCarouselItem, ButtonData, ImageEditorItem, OpenModalDialogData } from 'src/app/shared/models/shared.models';
import { SignalROperationUpdateEvent } from 'src/app/shared/models/signalr-events';
import { AzureBlobClientHelperService } from 'src/app/shared/services/azure-blob-client-helper-services/azure-blob-client-helper.service';
import { ImageCarouselHelperService } from 'src/app/shared/services/image-carousel-helper-services/image-carousel-helper.service';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { SignalRHubService } from 'src/app/shared/services/signalr-services/signalr-hub.service';

@Component({
  selector: 'app-image-editor-template',
  templateUrl: './image-editor-template.component.html',
  styleUrls: ['./image-editor-template.component.scss']
})
export class ImageEditorTemplateComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild('imageEditor') imageEditorObj?: ImageEditorComponent;
  @Input() id!: string;
  @Input() imageEditorItem!: ImageEditorItem;
  @Input() assetId!: string;
  @Input() assetPhotosCarouselItem!: AssetPhotosCarouselItem;
  cancelButtonData: ButtonData = { label: ButtonLabels.CANCEL, type: ButtonTypes.PRIMARY_NO_BORDER, name: ButtonNames.CANCEL };
  confirmButtonData: ButtonData = { label: ButtonLabels.SAVE_FOR_IMAGE_EDIT, type: ButtonTypes.PRIMARY_BASIC, name: ButtonNames.SAVE_FOR_IMAGE_EDIT, width: '120px' };
  uploadProgressMessage: string = '';
  isModalDisabled: boolean = false;
  thumbnailGenerationResponse!: SignalROperationUpdateEvent;

  private subscriptions = new Subscription();

  constructor(
    private azureBlobClientHelperService: AzureBlobClientHelperService,
    private imageCarouselHelperService: ImageCarouselHelperService,
    private modalDialogService: ModalDialogService,
    private signalRHubService: SignalRHubService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.signalRHubService.signalROperationUpdateEvent$.subscribe((event) => {
        this.thumbnailGenerationResponse = event.data;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['imageEditorItem'] && !changes['imageEditorItem'].isFirstChange()) {
      this.imageEditorObj?.open('');
      this.created();
    }
  }

  public created(): void {
    if (this.imageEditorItem.url) {
      this.imageEditorObj?.open(this.imageEditorItem.url);
      return;
    }
    if (this.imageEditorItem.file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event?.target?.result as string;
        this.imageEditorObj?.open(base64String);
      };
      reader.onerror = (error) => {
        console.error('Error converting selected file into base64:', error);
      };
      reader.readAsDataURL(this.imageEditorItem.file);
    }
  }

  // Method call on click of Cancel button
  onCancelButtonClick() {
    const customData: CustomEvent = { eventSource: CustomEventSource.MODAL, eventType: CustomEventType.SAVE_EDITED_IMAGE, eventData: { id: this.id, cancel: true } };
    this.modalDialogService.emitModalDialogEvent(customData);
    this.closeModal();
  }

  // Method to close modal
  closeModal() {
    this.modalDialogService.closeModal();
  }

  onConfirmButtonClick() {
    this.isModalDisabled = true;
    this.setProgressMessage(0);
    const img = this.imageEditorObj?.getImageData() as ImageData;
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const context = canvas.getContext('2d');
    context?.putImageData(img, 0, 0);
    canvas.toBlob((res) => {
      if (this.imageEditorItem.file) {
        this.fetchSasUrlAndUploadFile(res as Blob, this.imageEditorItem.file?.name);
      }
      else if (this.imageEditorItem.url) {
        this.fetchSasUrlAndUploadFile(res as Blob, this.assetPhotosCarouselItem?.name, true);
      }
    });
  }

  fetchSasUrlAndUploadFile(file: Blob, fileName: string = '', deleteOldInstance: boolean = false) {
    this.azureBlobClientHelperService.getBlobURI(fileName, this.assetId).subscribe({
      next: (res) => {
        this.azureBlobClientHelperService.uploadFile(file, res.sasUrl).pipe(debounceTime(200)).subscribe({
          next: (uploadedBytes) => {
            const progressPercent = this.azureBlobClientHelperService.getProgressPercent(file.size, uploadedBytes);
            this.setProgressMessage(progressPercent);
            if (progressPercent === 100) {
              setTimeout(() => {
                this.checkIfImageThumbnailGenerationStarted(res.id, deleteOldInstance, 5);
              }, 5000);
            }
          },
          error: (err) => {
            this.setProgressMessage(-1);
            this.isModalDisabled = false;
          }
        });
      },
      error: (error) => {
        this.emitModalDialogEvent(false);
      }
    });
  }

  checkIfImageThumbnailGenerationStarted(imageId: string, deleteOldInstance: boolean, retryTimes: number) {
    if (!this.assetId) return;
    if (this.thumbnailGenerationResponse?.status?.toLowerCase() === 'success') {
      this.afterImageUploaded(deleteOldInstance);
      return;
    }
    if (this.thumbnailGenerationResponse?.status?.toLowerCase() === 'failed') {
      this.setProgressMessage(-1, 'Image has been uploaded successfully. Please refresh the page.');
      this.showSuccessFailureModal(true);
      return;
    }
    this.imageCarouselHelperService.getAssetPhotos(this.assetId).subscribe(res => {
      const isUploaded = res.filter(x => x.id === imageId)[0]?.isUploaded;
      if (!isUploaded && retryTimes > 0) {
        setTimeout(() => {
          this.checkIfImageThumbnailGenerationStarted(imageId, deleteOldInstance, retryTimes - 1);
        }, 5000);
      }
      else if (!isUploaded && retryTimes === 0) {
        this.setProgressMessage(-1, 'Image has been uploaded successfully. Please refresh the page.');
        this.showSuccessFailureModal(true);
      }
      else {
        this.afterImageUploaded(deleteOldInstance);
      }
    });
  }

  afterImageUploaded(deleteOldInstance: boolean) {
    if (deleteOldInstance) {
      this.deleteImage(this.assetPhotosCarouselItem);
    }
    else {
      this.showSuccessFailureModal(true);
    }
  }

  deleteImage(item: AssetPhotosCarouselItem) {
    this.imageCarouselHelperService.deleteAssetPhoto(item.id, true).subscribe({
      next: () => {
        this.showSuccessFailureModal(true);
      },
      error: () => {
        this.showSuccessFailureModal(true);
      }
    });
  }

  showSuccessFailureModal(success: boolean) {
    this.emitModalDialogEvent(success);

    const data = new OpenModalDialogData();
    data.templateName = success ? ModalTemplateTypes.SUCCESS_TEMPLATE : ModalTemplateTypes.ERROR_TEMPLATE;
    data.customData.message = this.uploadProgressMessage;
    this.modalDialogService.openModal(data);
  }

  emitModalDialogEvent(success: boolean) {
    const customData: CustomEvent = { eventSource: CustomEventSource.MODAL, eventType: CustomEventType.SAVE_EDITED_IMAGE, eventData: { id: this.id, success: success } };
    this.modalDialogService.emitModalDialogEvent(customData);
  }

  setProgressMessage(progress: number, customMessage?: string) {
    if (customMessage) {
      this.uploadProgressMessage = customMessage;
    }
    else if (progress === -1) {
      this.uploadProgressMessage = 'Failed to save file. Please contact admin';
    }
    else if (progress === 0) {
      this.uploadProgressMessage = '';
    }
    else if (progress !== 100) {
      this.uploadProgressMessage = `File save in progress...${progress}%`;
    }
    else {
      this.uploadProgressMessage = 'File saved successfully!';
    }
  }
}
