<ejs-treegrid [clipMode]="clipMode" [allowResizing]='allowResizing' #treegrid
  [hasChildMapping]='hasChildMapping' [parentIdMapping]='parentIdMapping' [idMapping]='idMapping'
  [allowPaging]="allowPaging" [allowSorting]="allowSorting" [height]="gridHeight" [toolbar]='toolbarOption'
  [pageSettings]="pageSettings" [ngClass]="gridUIType" [rowHeight]="40" [dataSource]='data'
  [childMapping]='childMapping' (expanded)="rowExpanded($event)" enableCollapseAll='enableCollapseAll'
  (collapsing)="collapsed($event)" (expanded)="expanded($event)" (expanding)="expanding($event)"
  (rowSelected)="rowSelectedOnGridClick($event)" (rowDeselected)="rowDeselectedOnGridClick($event)"
  (rowSelecting)="rowSelecting($event)" (cellSelected)="cellSelectedOnGridClick($event)"
  [contextMenuItems]='contextMenuItems' [selectionSettings]="selectionSettings"
  (actionComplete)="onActionComplete($event)" [autoCheckHierarchy]="autoCheckHierarchy" [query]="query"
  (contextMenuClick)='contextMenuClick($event)' (checkboxChange)="checkBox($event)" [treeColumnIndex]='treeColumnIndex'
  [loadingIndicator]="loadingIndicator" (dataBound)="dataBound($event)">
  <e-columns>
    <e-column [customAttributes]="headerCustomAttributes"
      *ngIf="gridUIType === 'checkbox-flat-grid' || gridUIType === 'checkbox-tree-grid'" type='checkbox'
      width='50'></e-column>
    <ng-container *ngFor="let column of columns">
      <e-column [isPrimaryKey]='column.isPrimaryKey' [visible]="column.visible"
        [customAttributes]="headerCustomAttributes" [field]="column.field" [headerText]="column.headerText"
        [showCheckbox]="column.showCheckbox" [textAlign]="column.textAlign" width='{{column.headerWidth}}'>
        <ng-template #template let-data>
          <div *ngIf="column.field && showCellValue(column, data)">
            <div *ngIf="!column.customTemplate" [ngClass]="column?.cssClass">
              {{ !column.pipes ? data[column.field] : data[column.field] | multiplePipes : column.pipes }}
            </div>
            <app-signal-status *ngIf="column.customTemplate === 'SignalStatus'" [label]="data[column.field]"
              [onlineStatus]="data[column.field]"></app-signal-status>
            <app-asset-status-indicator *ngIf="column.customTemplate === 'AssetStatusIndicator'"
              [data]="data[column.field]" [showEmptyPill]="column.customTemplateProps?.['showEmptyPill']" />
            <app-cellular-signal-strength *ngIf="column.customTemplate === 'CellularSignalStrength'"
              [data]="data[column.field]" />
            <app-asset-status-bar *ngIf="column.customTemplate === 'AssetStatusBarComponent'"
              [statusIndicators]="data[column.field]"> </app-asset-status-bar>
            <app-setup-status [setupStatus]="data.setupStatus"
              *ngIf="column.customTemplate === 'SetupStatusComponent'"></app-setup-status>
            <div *ngIf="column.customTemplate === 'RSSIComponent'" [ngClass]="column?.cssClass">
              <div class="rssi-value">{{ data.bluetoothSignalStrength }}</div>
              <div class="rssi-units"> {{ data.bluetoothSignalUnits }}</div>
              <div class="rssi-icon">
                <app-signal-bar [strength]="data.bluetoothSignalStrengthLevel"></app-signal-bar>
              </div>
            </div>
            <app-battery-health-status *ngIf="column.customTemplate === 'BatteryHealthStatusComponent'"
              [lastValue]="data[column.field]"></app-battery-health-status>
            <app-kebab-menu *ngIf="column.customTemplate === 'KebabMenuComponent'" [label]="data[column.field]"
              [menuItems]="column.kebabMenuListItems" (kebabMenuClickEvent)="openKebabMenuFlyout($event)"
              [rowData]="data"></app-kebab-menu>
            <app-asset-battery-status *ngIf="column.customTemplate === 'AssetBatteryStatusComponent'"
              [batteryStatus]="data.battery"> </app-asset-battery-status>
            <app-asset-single-status *ngIf="column.customTemplate === 'AssetSingleStatusComponent'"
              [statusIndicator]="data.status"> </app-asset-single-status>
            <app-asset-show-graph *ngIf="column.customTemplate === 'AppAssetShowGraphComponent'"
              [ngClass]="column?.cssClass" [data]="data[column.field]"> </app-asset-show-graph>
            <app-last-communication-template *ngIf="column.customTemplate === 'LastCommunicationTemplateComponent'"
              [data]="data[column.field]"></app-last-communication-template>
            <app-connected-sensors-template *ngIf="column.customTemplate === 'ConnectedSensorsTemplateComponent'"
              [connectedSensorsInfo]="data[column.field]"></app-connected-sensors-template>
            <app-connection-status-template *ngIf="column.customTemplate === 'ConnectionStatusTemplateComponent'"
              [connectionStatus]="data[column.field]"></app-connection-status-template>
            <app-middle-ellipsis-template *ngIf="column.customTemplate === 'MiddleEllipsisTemplateComponent'"
              [value]="data[column.field]" [additionalCss]="column.cssClass"></app-middle-ellipsis-template>
            <app-campaign-status *ngIf="column.customTemplate === 'CampaignStatusTemplateComponent'"
              [campaignStatus]="data[column.field]" [type]="column.field"></app-campaign-status>
              <app-campaign-status-indicator *ngIf="column.customTemplate === 'CampaignStatusIndicatorTemplateComponent'"
              [campaignStatus]="data[column.field]"></app-campaign-status-indicator>
              <app-asset-hierarchy-tool-tip *ngIf="column.customTemplate === 'AssetHierarchyToolTipComponent'" 
              [label]="data[column.field]" [assetId]="data.equipmentId"></app-asset-hierarchy-tool-tip>
              <app-artifact-status-tooltip *ngIf="column.customTemplate === 'ArtifactStatustooltipComponent'" [otaSyncStatus]="data[column.field]" [reason]="data.reason"></app-artifact-status-tooltip>
              <app-format-date-time *ngIf="column.customTemplate === 'FormatDateTimeComponent'" [date]="data[column.field]"></app-format-date-time>
          </div>
        </ng-template>
      </e-column>
    </ng-container>
  </e-columns>
</ejs-treegrid>

<div #flyoutContainer class="grid-flyout-container">
  <ejs-listbox [dataSource]='flyoutMenuItems' [fields]='flyOutItemFields' cssClass="kebab-menu-flyout"
    *ngIf="showFlyOutItems" (change)="flyOutItemSelected($event)">
    <ng-template #itemTemplate="" let-data="">
      <div class="kebab-menu-flyout-item">
        <img [src]='data.icon' alt="icon" />
        <div [title]="data.label"> {{data.label}}</div>
      </div>
    </ng-template>
  </ejs-listbox>
</div>