
<div class="filter-container">
    <span *ngFor="let item of filterMenuListItems">
      <ejs-dropdownlist *ngIf="item.isChecked && item.filterName !== 'Date Created'" [cssClass]='component==="artifact"?"artifact-dropdown":"dropdown"' [placeholder]="item.filterName"  [dataSource]='item.data' [showClearButton]="true" [allowFiltering]="true" [fields]='fields'
      (change)="onInputChange($event, item.filterName)" value={{item.value}} popupHeight="300px">
     </ejs-dropdownlist>
     <ejs-daterangepicker *ngIf="item.filterName === 'Date Created' && item.isChecked" [class]='component==="artifact"?"artifact-dropdown":"dropdown"' cssClass="datetime" [placeholder]="item.filterName" (change)="onInputChange($event, item.filterName)" value={{item.value}} [format]='format' allowFiltering (cleared)="onClear($event, item.filterName)"></ejs-daterangepicker>
    </span>
   <app-custom-menu class="add-filter-btn" (kebabMenuClickEvent)="openFilterMenuFlyout($event)" [menuItems]="filterMenuListItems" id="filter-menu"></app-custom-menu>
  </div>

  <div #flyoutContainer class="grid-flyout-container">
    <ejs-listbox [dataSource]='flyoutMenuItems' [fields]='flyOutItemFields' cssClass="filter-menu-flyout"
      *ngIf="showFlyOutItems" >
      <ng-template #itemTemplate="" let-data="" >
        <div class="filter-menu-flyout-item">
          <ejs-checkbox type="checkbox" (change)="filterMenuchange(data, $event)" [checked]="data.isChecked" class="filter-checkbox"/> 
          <div [title]="data.filterName" class="filter-menu-text"> {{data.filterName}}</div>
        </div>
      </ng-template>
    </ejs-listbox>
</div>