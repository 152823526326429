import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ChangedEventArgs } from '@syncfusion/ej2-angular-inputs';
import { FieldSettingsModel } from '@syncfusion/ej2-angular-navigations';
import { CustomEventSource, CustomEventType } from 'src/app/shared/app-constants/shared.enum';
import { CustomEvent } from 'src/app/shared/models/custom-event';

@Component({
  selector: 'app-filter-component',
  templateUrl: './filter-component.component.html',
  styleUrls: ['./filter-component.component.scss']
})
export class FilterComponent {
  @ViewChild('flyoutContainer') flyoutContainer!: ElementRef;
  @Input() component: string = '';
  @Input() filterMenuListItems:any[] = [];
  @Input() tab:boolean = false;
  @Output() filterEvent = new EventEmitter<CustomEvent>();
  @Output() selectFilterEvent = new EventEmitter<CustomEvent>();

  artifactData: Object[] = [];
  fields: any = { text: 'name', value: 'name' };
  flyOutItemFields: FieldSettingsModel = { text: 'label' };
  flyoutMenuItems: string[] = []; //Items to be shown in flyout
  format: string = 'yyyy-MM-dd';
  rowDataForFlyoutMenu: any; //The row model will be passed here to detect the row
  showFlyOutItems: boolean = false; //Used to show hide the items inside flyout
  showFlyOutItems1: boolean = false;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    this.closeFilterFlyout(event.srcElement.id as string);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.closeFilterFlyout();
  }

  onInputChange(args:ChangedEventArgs, filterName:string) {
    let filterData;
    console.log('args', args);
    if (filterName === 'Date Created' && args?.value) {
      const isofromDate = new Date(args?.value[0]).toISOString();
      const isotoDate = new Date(args?.value[1]).toISOString();
      filterData = [isofromDate, isotoDate];
    } else {
      filterData = args.value;
    }
    const event: CustomEvent = { eventType: CustomEventType.CLICK, eventSource: CustomEventSource.KEBAB_MENU, eventData: { clickEvent: args, items: filterData, name: filterName } };
    this.filterEvent.emit(event);
  }

  onClear(args:ChangedEventArgs, filterName:string) {
    const event: CustomEvent = { eventType: CustomEventType.CLICK, eventSource: CustomEventSource.KEBAB_MENU, eventData: { clickEvent: args, items: args.value, name: filterName } };
    this.filterEvent.emit(event);
  }

  closeFilterFlyout(originator?: string) {
    const ele = this.flyoutContainer.nativeElement;
    if (ele && originator !== 'filter-menu') {
      ele.style.display = 'none';
    }
  }

  openFilterMenuFlyout(e: CustomEvent) {
    if (e.eventSource === CustomEventSource.KEBAB_MENU && e.eventType === CustomEventType.CLICK) {
      this.showFlyOutItems = false;
      this.flyoutMenuItems = e.eventData.items;
      this.rowDataForFlyoutMenu = e.eventData.rowData;
      const ele = this.flyoutContainer.nativeElement;
      if (!ele) return;
      ele.style.display = 'block';
      const tab:any = document.getElementById('tab_default')?.getBoundingClientRect();
      ele.style.top = this.tab ? e.eventData.clickEvent.pageY - tab?.y  - 22 - window.scrollY + 'px' : e.eventData.clickEvent.pageY + 20 + 'px';
      const defaultFlyoutWidth = ele.clientWidth ? ele.clientWidth + 20 : 140;
      ele.style.left = this.tab ? e.eventData.clickEvent.pageX - tab?.x + 120 - defaultFlyoutWidth - window.scrollX + 'px' : e.eventData.clickEvent.pageX + 120 - defaultFlyoutWidth + 'px';
      setTimeout(() => {
        this.showFlyOutItems = true;
      });
    }
  }

  filterMenuchange(data: any, e: any) {
    const event: CustomEvent = { eventType: CustomEventType.CLICK, eventSource: CustomEventSource.KEBAB_MENU, eventData: { clickEvent: e, data: data } };
    this.selectFilterEvent.emit(event);
    this.closeFilterFlyout();
  }
}

