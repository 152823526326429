import { Component, Input, OnInit } from '@angular/core';
import { format } from 'date-fns';

@Component({
  selector: 'app-format-date-time',
  templateUrl: './format-date-time.component.html',
  styleUrls: ['./format-date-time.component.scss']
})
export class FormatDateTimeComponent implements OnInit {
  @Input() date: Date = new Date();
  formatedDateTime: string = '';
  ngOnInit() {
    this.formatDateTime();
  }

  formatDateTime() {
    console.log('date', this.date);
    this.formatedDateTime = format(new Date(this.date), 'dd-MM-yyyy HH:mm');
    console.log('formatedDate', this.formatedDateTime, this.date);
  }
}
