<form [formGroup]="formGroup" *ngIf="formGroup" class="form-container">
  <h2>Create Campaign</h2>
  <div class="row-container">
    <h3>Campaign Name*</h3>
    <input name type="text" formControlName="campaignName" required />
  </div>
  <div style="display: flex">
    <div class="row-container">
      <h3>Campaign Type*</h3>
      <ejs-dropdownlist class="dropdown" formControlName="campaignType" [dataSource]="campaignTypes" [fields]="field"
        (change)="onChange($event)" required/>
    </div>
    <div class="firmware-type-container" *ngIf="campaignType === 'firmware'">
      <h3>Firmware Type</h3>
      <ejs-dropdownlist class="dropdown" formControlName="firmwareType" [dataSource]="firmwareTypes"  (change)="onfirmwareTypeChange($event)"/>
    </div>
  </div>
  <div class="row-container">
    <h3>Artifacts</h3>
    <ejs-dropdownlist formControlName="artifacts" [dataSource]="artifactData" [fields]="fields" [showClearButton]="true"
      [allowFiltering]="true" (change)="onArtifactChange($event)" />
  </div>
  <h2>When</h2>
  <div class="checkbox-container">
    <ejs-radiobutton [checked]="!later" label="Now" (change)="onCheckChange($event, 'now')"></ejs-radiobutton>
    <ejs-radiobutton  [checked]="later" label="Later" (change)="onCheckChange($event, 'later')" name="later"></ejs-radiobutton>
  </div>
  <div class="date-time-container" *ngIf="later">
    <div class="date-container">
      <h3>Date</h3>
      <ejs-datepicker formControlName="date" class="date-time"></ejs-datepicker>
    </div>
    <div class="time-container">
      <h3>Time</h3>
      <ejs-timepicker formControlName="time" [format]='timePickerFormat' [step]='timeInterval' class="date-time" />
    </div>
  </div>
  <div class="modal-footer">
    <app-custom-button [buttonData]="cancelButtonData" (buttonClick)="onCancel()" />
    <app-custom-button [buttonData]="createButtonData" (buttonClick)="onCreate()" />
  </div>
</form>