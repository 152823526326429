import { Fetch } from '@syncfusion/ej2-base';
import { CrudOptions, DataOptions, DataResult, Query, WebApiAdaptor  } from '@syncfusion/ej2-data';
import { EventEmitter } from '@angular/core';
import { PipeTransformData } from './shared.models';

export class TreeGridColumnData {
  field?: string;
  headerText?: string;
  headerWidth?: string = undefined;
  textAlign?: string = 'Left';
  cssClass?: string;
  customTemplate?: TreeGridColumnCustomTemplateData;
  customTemplateProps?: { [key: string]: any } = {};
  kebabMenuListItems?: KebabMenuListItem[];
  showCheckbox?: boolean = false;
  hideValueOnRowExpand?: boolean = false;
  pipes?: PipeTransformData[];
  isPrimaryKey?: boolean = false;
  visible?: boolean = true;
}

export interface KebabMenuListItem {
  label: string;
  icon: string;
}

export enum TreeGridColumnCustomTemplateData {
  AssetStatusBarComponent = 'AssetStatusBarComponent',
  SignalStatus = 'SignalStatus',
  KebabMenuComponent = 'KebabMenuComponent',
  AssetBatteryStatusComponent = 'AssetBatteryStatusComponent',
  AssetSingleStatusComponent = 'AssetSingleStatusComponent',
  AppAssetShowGraphComponent = 'AppAssetShowGraphComponent',
  AssetStatusIndicator = 'AssetStatusIndicator',
  CellularSignalStrength = 'CellularSignalStrength',
  SetupStatusComponent = 'SetupStatusComponent',
  RSSIComponent = 'RSSIComponent',
  LastCommunicationTemplateComponent = 'LastCommunicationTemplateComponent',
  ConnectedSensorsTemplateComponent = 'ConnectedSensorsTemplateComponent',
  ConnectionStatusTemplateComponent = 'ConnectionStatusTemplateComponent',
  BatteryHealthStatusComponent = 'BatteryHealthStatusComponent',
  MiddleEllipsisTemplateComponent = 'MiddleEllipsisTemplateComponent',
  CampaignStatusTemplateComponent = 'CampaignStatusTemplateComponent',
  CampaignStatusIndicatorTemplateComponent = 'CampaignStatusIndicatorTemplateComponent',
  AssetHierarchyToolTipComponent = 'AssetHierarchyToolTipComponent',
  ArtifactStatustooltipComponent = 'ArtifactStatustooltipComponent',
  FormatDateTimeComponent = 'FormatDateTimeComponent'
}

export class PerceptivWebApiAdaptor extends WebApiAdaptor {
  constructor(public resultProcessed:EventEmitter<PerceptivWebApiData>) {
    super();
  }

  onResultProcessed(data:DataResult):DataResult {
    this.resultProcessed?.next(new PerceptivWebApiData(data));
    return data;
  }

  override processResponse(data: DataResult, ds?: DataOptions | undefined, query?: Query | undefined, xhr?: Request | undefined, request?: Fetch | undefined, changes?: CrudOptions | undefined): Object {
    return this.onResultProcessed(super.processResponse(data, ds, query, xhr, request, changes));
  }
}
export class PerceptivWebApiData {
  constructor(public data:DataResult) {
    this.count = data.count;
    this.result = data.result;
  }

  public count?: number;
  public result?: Object;
}
