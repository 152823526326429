import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-campaign-status-indicator',
  templateUrl: './campaign-status-indicator.component.html',
  styleUrls: ['./campaign-status-indicator.component.scss']
})
export class CampaignStatusIndicatorComponent {
  @Input() campaignStatus?:string;
  showStatusOnHover:boolean = false;
  showValue(showStatus: boolean) {
    this.showStatusOnHover = showStatus;
  }

  resetStatus() {
    this.showStatusOnHover = false;
  }
}
