<!-- <ejs-tooltip id="tooltipFocus" [content]="flyoutContent" opensOn='Auto'> -->
<span class="status-bar">
    <span  class="status-bar-item" [ngClass]="{'alert-bg':campaignStatus==='failed','warning-bg':campaignStatus==='pending'||campaignStatus==='initiate-abort'|| campaignStatus==='aborted','good-bg':campaignStatus==='completed','system-bg':campaignStatus==='waiting'||campaignStatus==='in-progress', 'system-offline-bg':campaignStatus==='5'}"  (mouseover)="showValue(true)" (focus)="showValue(true)" (blur)="resetStatus()" (mouseleave)="resetStatus()">
      <span class="status-text" *ngIf="showStatusOnHover">{{campaignStatus}}</span>
      <span *ngIf="!showStatusOnHover">
        <img *ngIf="campaignStatus==='failed'" src="assets/icons-and-logos/status/failed.svg" alt="failed">
        <img *ngIf="campaignStatus==='pending'||campaignStatus==='initiate-abort'|| campaignStatus==='aborted'" src="assets/icons-and-logos/status/alert.svg" alt="pending">
        <img *ngIf="campaignStatus==='completed'" src="assets/icons-and-logos/status/good.svg" alt="completed">
        <img *ngIf="campaignStatus==='waiting' || campaignStatus==='in-progress'" src="assets/icons-and-logos/status/initiated.svg" alt="waiting">
      </span>
  </span>
</span>
<!-- </ejs-tooltip> -->
  <!-- <span *ngIf="campaignStatus!=='completed'&&campaignStatus!=='failed'&&campaignStatus!=='pending'&&campaignStatus!=='waiting'">
    {{campaignStatus}}
  </span> -->