
export enum AssetStatus {
  ALERT = '1',
  WARNING = '2',
  GOOD = '3',
  SYSTEM = '4',
  SYSTEM_OFFLINE = '5'
}

export enum AssetBatteryStatus {
  EMPTY = '1',
  LOW = '2',
  HALF = '3',
  THREE_QUARTER = '4',
  FULL = '5'
}

export enum CriticalityStrategy {
  VERY_HIGH = 'Very High',
  HIGH = 'High',
  MODERATE = 'Moderate',
  LOW = 'Low',
  VERY_LOW = 'Very Low'
}

export enum GridUIType {
  BORDERED_TREE_GRID = 'bordered-tree-grid',
  BORDERLESS_FLAT_GRID = 'borderless-flat-grid',
  BORDERED_FLAT_GRID = 'bordered-flat-grid',
  ALTERNATE_ROW_COLOR_FLAT_GRID = 'alternate-row-color-flat-grid',
  CHECK_BOX_FLAT_GRID = 'checkbox-flat-grid',
  CHECK_BOX_TREE_GRID = 'checkbox-tree-grid',
  DEFAULT = ''
}

export enum IconSize {
  SIZE_XSM = 'size_xsm',
  SIZE_SM = 'size_sm',
  SIZE_LG = 'size_lg',
  SIZE_XL = 'size_xl',
  SIZE_XXL = 'size_xxl',
  SIZE_2XL = 'size_2xl',
  SIZE_4XL = 'size_4xl',
  SIZE_5XL = 'size_5xl',
}

export enum ButtonTypes {
  PRIMARY_BASIC = 'e-primary-basic',
  PRIMARY_BORDER = 'e-primary-border',
  PRIMARY_NO_BORDER = 'e-primary-no-border',
  PRIMARY_NO_BORDER_WITH_UNDERLINE = 'e-primary-no-border-underline',
  DANGER_NO_BORDER = 'e-danger-no-border',
  SECONDARY_BASIC = 'e-secondary-basic',
  PRIMARY_TERTIARY_BASIC = 'e-primary-tertiary-basic',
  SECONDARY_TERTIARY_BASIC = 'e-secondary-tertiary-basic',
  PRIMARY_DANGER_BASIC = 'e-primary-danger-basic',
  SECONDARY_DANGER_BASIC = 'e-secondary-danger-basic',
  BRAND_GREEN_BASIC = 'e-brand-green-basic',
  NEURAL_NO_BORDER = 'e-neutral-no-border',
}

export enum ButtonLabels {
  ADD_NEW_USER = 'Add New User +',
  ADD_NEW_ROLE = 'Add Role +',
  ADMIN = 'Admin',
  APPROVE = 'Approve',
  ASSIGN_ROLE = 'Assign Role',
  ASSIGN_ROLE_AND_SCOPE = 'Assign Role & Scope',
  BACK = 'Back',
  CAMPAIGN_SUMMARY = 'Campaign Summary',
  CANCEL = 'Cancel',
  CONFIRM = 'Confirm',
  CREATE = 'Create',
  CREATE_NEW_ROLE = 'Create New Role +',
  CREATE_GROUP = 'Create Group',
  DASHBOARD = 'Dashboard',
  DISASSOCIATE_GATEWAY = 'Disassociate Gateway',
  EDIT_PARAMETERS = 'Edit Parameters',
  EDIT_SCHEDULE = 'Edit Schedule',
  EDIT_THRESHOLDS = 'Edit Thresholds',
  GROUP_SUMMARY = 'Group Summary',
  INITIATE_CAMPAIGN = 'Initiate Campaign',
  MARK_AS_COMPLETED = 'Mark as Completed',
  NEW_AREA = 'New Area +',
  NEW_ENTERPRISE = 'New Enterprise +',
  NEW_EQUIPMENT = 'New Equipment +',
  NEW_ROLE = 'New Role +',
  NEW_SITE = 'New Site +',
  NEW_TENANT = 'New Tenant +',
  NEW_UNIT = 'New Unit +',
  OKAY = 'OKAY',
  OPEN_GRAPH = 'Open Graph',
  OPERATIONS = 'Operations',
  POWERBI_GRAPH = 'PowerBI Graph',
  REQUEST_MEASUREMENT = 'Request Measurement',
  REJECT = 'Reject',
  ROLLBACK = 'Rollback',
  SAVE = 'Save',
  SAVE_FOR_IMAGE_EDIT = 'Save',
  SAVE_AND_EXIT = 'Save & Exit',
  TRACK_ENTERPRISE = 'Track Enterprise +',
  UNASSIGN_EQUIPMENT = 'Unassign Equipment',
  VIEW_CERTIFICATE = 'View Certificate',
  VIEW_EVENTS = 'View Events',
  VIEW_LOGS = 'View Logs',
  VIEW_TENANTS = 'View Tenants >',
  VIEW_USERS = 'View Users >',
  EDIT = 'Edit',
  RESTORE_DEFAULTS = 'Restore Defaults',
  IMPORT = 'Import',
  EXPORT = 'Export',
  UPLOAD = 'Upload',
  UPDATE = 'Update',
  USER_PREFERENCES = 'User Preferences',
  NOTIFICATION_PREFERENCES = 'Notification Preferences',
  ACTIVE = 'Activate',
  INACTIVE = 'Inactivate',
  EDIT_FREQUENCY = 'Edit Frequency',
  NEW_SUBSCRIPTION = 'New Subscription +',
  CONFIGURE = 'Configure',
  SAVE_FOR_TECH_DETAILS_EDIT = 'Save',
  CONFIGURE_SEQUENCE = 'Configure Sequence',
  CONFIGURE_PROTOCOL = 'Configure Protocol',
  RESTART_GATEWAY = 'Restart Gateway',
  UNASSIGN_SENSOR = 'Unassign Sensor',
  DISASSOCIATE_SENSOR = 'Disassociate Sensor',
  FORCE_DISASSOCIATE_SENSOR = 'Force Disassociate Sensor',
  DISMISS_ALL = 'Dismiss All',
  VIEW_ALL_ALERT_SIGNAL_NOTIFICATIONS = 'View All',
  VIEW_ALL_COMMAND_SIGNAL_NOTIFICATIONS = 'View All',
  TODAY = 'Today',
  APPLY = 'Apply',
  CUSTOMER_INTERFACE = 'Customer Interface',
  ADMIN_TOOLS = 'Admin Tools',
}

export enum ButtonNames {
  ADD_NEW_USER = 'add-new-user',
  ADD_NEW_ROLE = 'add-new-role',
  ADMIN_BUTTON = 'admin-btn',
  ASSIGN_ROLE = 'assign-Role',
  ASSIGN_ROLE_AND_SCOPE_MULTIPLE = 'assign-role-and-scope-multiple',
  ASSIGN_ROLE_AND_SCOPE = 'assign-role-and-scope',
  APPROVE = 'Approve',
  BACK = 'back',
  CAMPAIGN_SUMMARY = 'campaign-summary',
  CANCEL = 'cancel',
  CONFIRM = 'confirm',
  CONFIGURE = 'configure',
  CONFIGURE_PROTOCOL = 'configure-protocol',
  CONFIGURE_SEQUENCE = 'configure-sequence',
  CREATE = 'create',
  CREATE_GROUP = 'create-group',
  CREATE_NEW_ROLE = 'create-new-role',
  DASHBOARD_BUTTON = 'dashboard-btn',
  DISASSOCIATE_GATEWAY = 'disassociate-gateway',
  EDIT_PARAMETERS = 'edit-parameters',
  EDIT_SCHEDULE = 'edit-schedule',
  EDIT_THRESHOLDS = 'edit-thresholds',
  GROUP_SUMMARY = 'group-summary',
  INITIATE_CAMPAIGN = 'initiate-campaign',
  MARK_AS_COMPLETED = 'mark-as-completed',
  NEW_AREA = 'new-area',
  NEW_ENTERPRISE = 'new-enterprise',
  NEW_EQUIPMENT = 'new-equipment',
  NEW_ROLE = 'new-role',
  NEW_SITE = 'new-site',
  NEW_TENANT = 'new-tenant',
  NEW_UNIT = 'new-unit',
  OKAY = 'okay',
  OPEN_GRAPH = 'open-graph',
  OPERATIONS = 'operations',
  POWERBI_GRAPH = 'powerBIGraph',
  REQUEST_MEASUREMENT = 'request-management',
  REJECT = 'Reject',
  ROLLBACK = 'rollback',
  SAVE = 'save',
  SAVE_FOR_IMAGE_EDIT = 'save-for-image-edit',
  SAVE_MODAL = 'save-modal',
  SAVE_AND_EXIT = 'save-and-exit',
  SAVE_AND_EXIT_FOR_AREA = 'save-and-exit-for-area',
  SAVE_AND_EXIT_FOR_ENTERPRISE = 'save-and-exit-for-enterprise',
  SAVE_AND_EXIT_FOR_EQUIPMENT = 'save-and-exit-for-equipment',
  SAVE_AND_EXIT_FOR_SITE = 'save-and-exit-for-site',
  SAVE_AND_EXIT_FOR_UNIT = 'save-and-exit-for-unit',
  SAVE_FOR_AREA = 'save-for-area',
  SAVE_FOR_ENTERPRISE = 'save-for-enterprise',
  SAVE_FOR_EQUIPMENT = 'save-for-equipment',
  SAVE_FOR_SITE = 'save-for-site',
  SAVE_FOR_UNIT = 'save-for-unit',
  TRACK_ENTERPRISE = 'track-enterprise',
  UNASSIGN_EQUIPMENT = 'unassign-equipment',
  UPDATE = 'update',
  VIEW_CERTIFICATE = 'view-certificate',
  VIEW_EVENTS = 'view-events',
  VIEW_LOGS = 'view-logs',
  VIEW_TENANTS = 'view-tenants',
  VIEW_USERS = 'view-users',
  EDIT = 'Edit',
  SAVE_FOR_THRESHOLD = 'save-for-threshold',
  RESTORE_DEFAULTS = 'restore-defaults',
  IMPORT = 'import',
  EXPORT = 'export',
  UPLOAD = 'upload',
  SAVE_AND_EXIT_FOR_ROLE = 'save-and-exit-for-role',
  SAVE_FOR_ROLE = 'save-for-role',
  LOGOUT = 'Logout',
  USER_PREFERENCES = 'User-Preferences',
  EMAIL = 'email',
  NAME = 'name',
  NOTIFICATION_PREFERENCES = 'Notification-Preferences',
  SAVE_FOR_NOTIFICATIONS = 'save-for-notifications',
  SAVE_AND_EXIT_FOR_NOTIFICATIONS = 'save-and-exit-for-notifications',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EDIT_FREQUENCY = 'edit-frequency',
  NEW_SUBSCRIPTION = 'new-subscription',
  SAVE_FOR_TECH_DETAILS_EDIT = 'save-for-tech-details',
  RESTART_GATEWAY = 'restart-gateway',
  UNASSIGN_SENSOR = 'unassign-sensor',
  DISASSOCIATE_SENSOR = 'disassociate-sensor',
  FORCE_DISASSOCIATE_SENSOR = 'force-disassociate-sensor',
  DISMISS_ALL = 'dismiss-all',
  VIEW_ALL_ALERT_SIGNAL_NOTIFICATIONS = 'view-all-alert-signal-notifications',
  VIEW_ALL_COMMAND_SIGNAL_NOTIFICATIONS = 'view-all-command-signal-notifications',
  TODAY = 'today',
  APPLY = 'apply',
  CUSTOMER_INTERFACE = 'customer-interface',
  ADMIN_TOOLS = 'admin-tools',
}

export enum ButtonTemplatePosition {
  NONE = 'none',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum GatewayState {
  REGISTERED = 'Registered',
  PROVISIONED = 'Provisioned',
  COMMISSIONED = 'Commissioned',
  DECOMMISSIONED = 'Decommissioned'
}

export enum ModalTemplateTypes {
  SENSOR_THRESHOLDS = 'SensorThresholdsComponent',
  SENSOR_PARAMETER = 'SensorParameterComponent',
  SENSOR_SCHEDULER = 'SensorSchedulerComponent',
  CONFIRM_TEMPLATE = 'ConfirmTemplateComponent',
  CREATE_GROUP = 'CreateGroupComponent',
  SENSOR_TELEMETRY_GRAPH_S7100 = 'SensorTelemetryGraphS7100Component',
  ERROR_TEMPLATE = 'ErrorTemplateComponent',
  GRID_TEMPLATE = 'GridTemplateComponent',
  SUCCESS_TEMPLATE = 'SuccessTemplateComponent',
  IMAGE_EDITOR_TEMPLATE = 'ImageEditorTemplateComponent',
  INITIATE_CAMPAIGN = 'InitiateCampaignComponent',
  ROLE_AND_SCOPE_ASSIGNMENT = 'RoleAndScopeAssignmentComponent',
  CAROUSEL_TEMPLATE = 'ImageCarouselTemplateComponent',
  UPLOAD_ARTIFACT = 'UploadArtifactComponent',
  USER_PREFERENCES = 'UsersPreferencesComponent',
  ADD_TAGS = 'AddTagsComponent',
}

export enum CustomEventType {
  CLICK,
  CELL_CLICK,
  ROW_EXPANDED,
  KEBAB_ITEM_SELECTED,
  GRID_TEMPLATE_ITEMS_SELECTED,
  EDIT_IMAGE,
  IMAGE_CLICK,
  SAVE_EDITED_IMAGE,
  ROW_DESELECT,
  ROW_SELECTION_CHANGED,
  ROW_CHECKED,
  ROW_UNCHECKED,
  SAVE_USER_ROLE_ASSIGNMENT,
  SAVE_INDUSTRIAL_PROTOCOL_CONFIGURATION,
  SAVE_USER_PREFERENCES,
  IMAGE_DELETED,
  DATA_BOUND,
  EXPANDING,
  SAVE,
  CANCEL
}

export enum CustomEventSource {
  TREEGRID,
  EQUIPMENT_ALERT_CARD,
  FIXED_ALERT_CARD,
  ASSET_STATUS_ALERT_CARD,
  FIXED_TOP_CARD_CONTAINER,
  FULL_IMAGE_VIEW,
  BUTTON,
  MODAL,
  KEBAB_MENU,
  ADMIN_DASHBOARD_CARD,
  ASSET_PHOTOS_CAROUSEL,
  ROW_SELECTION_CHANGED
}

export enum ButtonSizes {
  SM = 'button-sm',
  MD = 'button-md',
  LG = 'button-lg',
}

export enum TreeGridColumns {
  FIRST_COLUMN = 'tree-grid-first-column',
  EQUIPMENT_COLUMN = 'tree-grid-equipmentName-column',
  LOCATION_COLUMN = 'tree-grid-location-column',
}

export enum GridEvent {
  ROW_SELECTED = 'rowSelected'
}

export enum AddressType {
  SHIPPING_ADDRESS = 'Shipping Address',
  BILLLING_ADDRESS = 'Billing Address'
}

export enum UnitType {
  PRODUCTION_MACHINERY = 'Production Machinery',
  ASSEMBLY_LINE_STATIONS = 'Assembly Line Stations',
  WORKSTATIONS = 'Workstations',
  RAW_MATERIALS_LINE = 'Raw Materials Line',
  FINISHED_GOODS_LINE = 'Finished Goods Line',
  QUALITY_CONTROL_LINE = 'Quality Control Line',
  AUTOMATION_LINE = 'Automation Line',
  DATA_COLLECTION_LINE = 'Data Collection Line',
  MILLING_MACHINES = 'Milling Machines',
  LATHES = 'Lathes',
  PRESSES = 'Presses',
  ROBOTIC_ARMS = 'Robotic Arms',
  CONVEYOR_BELTS = 'Conveyor Belts',
  INDUSTRIAL_OVENS = 'Industrial Ovens',
  WELDING_MACHINES = 'Welding Machines',
  CUTTING_MACHINES = 'Cutting Machines',
  INJECTION_MOLDING_MACHINES = 'Injection Molding Machines',
  CNC_MACHINES = 'CNC Machines',
  TESTING_STATION = 'Testing Station',
  MIXING_TANKS = 'Mixing Tanks',
  PACKAGING_MACHINERY = 'Packaging Machinery',
  CRANE_SYSTEMS = 'Crane Systems',
  COOLING_SYSTEMS = 'Cooling Systems',
  MATERIAL_HANDLING_MACHINES = 'Material Handling Machines',
  POWER_GENERATORS = 'Power Generators',
  CLEANING_MACHINES = 'Cleaning Machines',
  DUST_COLLECTION_MACHINE = 'Dust Collection Machine',
}

export enum EquipmentType {
  EQUIPMENT = 'Equipment',
  GEARBOX = 'Gearbox',
  MOTOR = 'Motor',
  FANS = 'Fans',
  BEARING = 'Bearing',
  CONVEYOR = 'Conveyor',
  BLOWER = 'Blower',
  SHEAVE = 'Sheave',
  BUSHING_BELT = 'Bushing Belt',
  SPROCKET = 'Sprocket',
  CHAIN = 'Chain',
  SYNCHRONOUS_PULLEY = 'Synchronous Pully',
  EQUIPMENT_RUBBER_BELTS = 'Equipment Rubber Belts',
  CONVEYOR_BELT = 'Conveyor Belt',
  ROLLER_CHAIN = 'Roller Chain',
  CONVEYOR_RETURN_COMPONENT = 'Conveyor Return Component',
  WEAR_STRIP = 'Wear Strip',
  GUIDE_RAIL = 'Guide Rail',
  GEAR_MOTOR = 'Gear Motor',
  OTHER = 'Other',
}

export enum SetupStatus {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In Progress',
  COMMISSIONED = 'Commissioned'
}

export enum CardHeading {
  PRIMARY_NEW_ENTERPRISE = 'New Enterprise',
  SECONDARY_NEW_ENTERPRISE = 'From Salesforce',
  PRIMARY_MANAGE_TENANTS = 'Manage Tenants',
  PRIMARY_MANAGE_TENANT_USERS = 'Manage Tenant Users',
  PRIMARY_NEW_SITE = 'Create New Site',
  PRIMARY_NEW_AREA = 'Create new Area for Site',
  PRIMARY_NEW_UNIT = 'Create new Unit for Area',
  PRIMARY_NEW_EQUIPMENT = 'Create new Equipment for Unit',
}

export enum TextSize {
  SIZE_XL = 'text-xl',
  SIZE_2XL = 'text-2xl',
}

export enum DayOfWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday'
}

export enum AssetPhotosCarouselItemType {
  PHOTO = 'photo',
  QR = 'qr'
}
export enum TreeChildMappers {
  permissions = 'permissions'
}

export enum SignalREventTypes {
  Notification = 'notification',
  AssetChangeSignal = 'assetChangeSignal',
  CommandSignal = 'CommandSignal',
  AlertSignal = 'AlertSignal',
  OperationUpdate = 'operationUpdate'
}

export enum CommandNames {
  RebootGateway = 'rebootGateway',
  ReadLowFrequency = 'readLowFrequency',
  ReadFullSpectrum = 'readFullSpectrum',
  ReadSensorConfiguration = 'readSensorConfiguration',
  RenewCertificate = 'renewCertificate',
  GetSensorSystemInformation = 'getSensorSystemInformation',
  GetGatewaySystemInformation = 'getGatewaySystemInformation'

}

export enum AssetChangeSignalOperationTypes {
  UpdateAsset = 'update_asset',
  LinkAsset = 'link_assets',
  UnlinkAsset = 'unlink_assets',
}

export enum AlertSignalEventTypes {
  Alarm = 'Alarm',
  Warning = 'Warning',
  AssetHealthStateChange = 'AssetHealthStateChange',
  DeviceOnline = 'DeviceOnline',
  DeviceOffline = 'DeviceOffline'
}

export enum OperationNames {
  ThumbnailGeneratation = 'thumbnailGeneratation'
}

export enum AssetHealthStatus {
  Alarm = 'Alarm',
  Warning = 'Warning',
  Healthy = 'Healthy'
}

export enum AssetPageNames {
  EnterpriseDetails,
  SiteDetails,
  AreaDetails,
  UnitDetails,
  EquipmentDetails,
  SensorDetails,
  GatewayDetails
}

export enum AssetViewMode {
  Admin,
  Customer
}
