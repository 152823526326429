import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { TooltipComponent } from '@syncfusion/ej2-angular-popups';
import { Subscription } from 'rxjs';
import { UserDetails } from 'src/app/admin/user-management/models/user-details';
import { UserService } from 'src/app/customer/user-management/services/user-services/user.service';
import { AppRouteConstants } from 'src/app/shared/app-constants/app-route-constants';
import { EventsConstants } from 'src/app/shared/app-constants/events-constants';
import { ModalDimensions } from 'src/app/shared/app-constants/modal-dialog-constants';
import { ButtonLabels, ButtonNames, ButtonTypes, CustomEventSource, ModalTemplateTypes } from 'src/app/shared/app-constants/shared.enum';
import { CustomEvent } from 'src/app/shared/models/custom-event';
import { LoginState } from 'src/app/shared/models/login-state';
import { ButtonData, OpenModalDialogData } from 'src/app/shared/models/shared.models';
import { ModalDialogService } from 'src/app/shared/services/modal-dialog-service/modal-dialog.service';
import { SessionService } from 'src/app/shared/services/session-management-services/session.service';
import { SpinnerService } from '../../shared/services/spinner-service/spinner.service';
import { IdleTimeoutService } from '../auth/idle-timeout.service';
import { UserCommonService } from 'src/app/shared/services/user-common-services/user-common.service';
import { Scope } from 'src/app/shared/app-constants/asset-constants';

@Component({
  selector: 'app-profile-sidebar',
  templateUrl: './profile-sidebar.component.html',
  styleUrls: ['./profile-sidebar.component.scss']
})

export class ProfileSidebarComponent implements OnInit {
  @Input()  toolTipControl?: TooltipComponent;
  logoutButtonData: ButtonData = { label: ButtonNames.LOGOUT, type: ButtonTypes.PRIMARY_NO_BORDER_WITH_UNDERLINE, name: ButtonNames.LOGOUT };
  public data: { [key: string]: Object }[] = [{ text: '.....', id: '...' }];
  subscriptions = new Subscription();
  userDetails!: UserDetails;

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private modalDialogService: ModalDialogService,
    private router: Router,
    private sessionService: SessionService,
    private spinner:SpinnerService,
    private userService: UserService,
    private idleTimeoutService: IdleTimeoutService,
    private commonUserService: UserCommonService
  ) {
    this.subscriptions.add(this.modalDialogService.modalDialogEventObservable().subscribe(res => {
      this.modalDialogEvent(res);
    }));
  }

  ngOnInit(): void {
    if (this.sessionService.userDetails)
      this.userDetails = this.sessionService.userDetails;
    this.prepareData();
  }

  prepareData() {
    const fullName = `${this.userDetails?.firstName ?? ''} ${this.userDetails?.lastName ?? ''}`;
    this.data = [
      { text: fullName ?? '', id: ButtonNames.NAME },
      { text: this.userDetails?.email ?? '', id: ButtonNames.EMAIL },
      { text: ButtonLabels.NOTIFICATION_PREFERENCES, id: ButtonNames.NOTIFICATION_PREFERENCES },
      { text: ButtonLabels.USER_PREFERENCES, id: ButtonNames.USER_PREFERENCES },
    ];
  }

  logout() {
    this.idleTimeoutService.clearSessionTracking();
    const logoutState: LoginState = {
      loginStatus: false,
      roleName: '',
      isAdminPage: false,
      isAdminRole: false
    };
    this.sessionService.loginState$.next(logoutState);
    const url = `/${AppRouteConstants.APP_LOGOUT_ROUTE}`;
    this.router.navigate([url], { replaceUrl: true });
  }

  public selectionChange(args: any) {
    if (args.elements[0].innerHTML === ButtonNames.LOGOUT) {
      this.spinner.showSpinner();
      this.logout();
    }
    if (args.elements[0].innerHTML === ButtonLabels.USER_PREFERENCES) {
      this.openUserPreferencesModal();
    }
    if (args.elements[0].innerHTML === ButtonLabels.NOTIFICATION_PREFERENCES) {
      this.notificationPreferences();
    }
    this.toolTipControl?.close();
  }

  openUserPreferencesModal() {
    const data = new OpenModalDialogData();
    data.templateName = ModalTemplateTypes.USER_PREFERENCES;
    data.dimension = ModalDimensions.LG;
    data.id = EventsConstants.USER_PREFERENCES_MODAL_ID;
    data.customData.userId = this.sessionService.userId;
    this.modalDialogService.openModal(data);
  }

  modalDialogEvent(e: CustomEvent) {
    if (e.eventSource === CustomEventSource.MODAL && e.eventData.id === EventsConstants.USER_PREFERENCES_MODAL_ID) {
      const timeZone: string = e.eventData.data.timeZone;
      const unitOfMeasure: string = e.eventData.data.unitOfMeasure;
      this.saveUserPreferences(timeZone, unitOfMeasure);
      this.modalDialogService.closeModal();
    }
  }

  saveUserPreferences(timeZone: string, unitOfMeasure: string) {
    this.userDetails.timeZone = timeZone;
    this.userDetails.unitOfMeasure = unitOfMeasure;
    if (!this.userDetails.sapCode) {
      this.userDetails.sapCode = 'test';
    }
    if (!this.userDetails.createdBy) {
      this.userDetails.createdBy = this.userDetails.email;
    }
    this.userDetails.updatedBy = this.userDetails.email;
    if (this.userDetails) {
      let scope = Scope.DEFAULT_SCOPE;
      if (!this.sessionService.isAdminRole && this.sessionService.allRolesAssignedToUser) scope = this.sessionService.allRolesAssignedToUser[0].scope;
      this.commonUserService.updateUser(this.sessionService.tenantId, this.userDetails, scope).subscribe((response) => {
        if (response) {
          const data = new OpenModalDialogData();
          data.templateName = ModalTemplateTypes.SUCCESS_TEMPLATE;
          data.customData.message = 'User details Updated Successfully';
          this.modalDialogService.openModal(data);
        }
      });
    }
  }

  notificationPreferences() {
    const url = `/${AppRouteConstants.NOTIFICATION_PREFERENCES}`;
    this.router.navigate([url]);
  }
}
